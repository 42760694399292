<ion-header>
  <ion-toolbar color="header">
    <ion-buttons slot="start">
      <ion-menu-button *ngIf="!localStorage.getIsNativeApp() && smallScreen" menu="appMenu" autoHide="false" id="appmenuButton"></ion-menu-button>
      <ion-button *ngIf="back" id="backButton" (click)="goBack()">
        <ion-icon name="arrow-back" color="font-header"></ion-icon>
      </ion-button>
      <ion-button *ngIf="save" [disabled]="!answersChanged" id="saveLessonButton" (click)="saveQuestionnaire()">
        <ion-icon name="save" color="font-header"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center" [hidden]="isMobile" color="font-header">
        <ion-icon name={{icon}} color="font-header"></ion-icon>
        <h1>
          {{title}}
        </h1>
    </ion-title>

    <div *ngIf="!localStorage.getIsNativeApp() && isDevelopVersion" slot="end" class="devMarker">
      Develop
    </div>

    <div *ngIf="!localStorage.getIsNativeApp()" slot="end" class="activityDiv">
      <button *ngIf="parserService.announcements.length > 0" mat-button id="showHideAnnouncements" (click)="showHideAnnouncements()">
        <mat-icon>notifications_none</mat-icon>
      </button>
      <button mat-button [matMenuTriggerFor]="menuRef" id="showActivities" (menuOpened)="menuOpened()">
        <mat-icon>feedback</mat-icon>
      </button>
      <mat-menu #menuRef="matMenu">
        <h4 style="text-align: center">
          {{'HOME.YOUR_ACTIVITYLOG' | translate}}
        </h4>
        <div *ngFor="let activity of loadedActivities" mat-menu-item>
          {{activity.text}}
          <div class="datetext">
            {{helperService.formatDate(activity.created_at, localStorage.getAppLanguage())}}
          </div>
        </div>
        <ion-content>
          <ion-infinite-scroll threshold="100px" id="scrollActivities">
            <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more data...">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-content>
      </mat-menu>
    </div>

    <div *ngIf="!localStorage.getIsNativeApp()" slot="end" class="languageDiv">
      <ion-select [value]="language" class="header" interface="popover" placeholder="GENERAL.SELECT_LANGUAGE"
         id="inputSelectedLanguage" (ionChange)="changeLanguage($event.detail.value)">
        <ion-select-option value="de" id="inputSelectedLanguagede">
          {{'GENERAL.GERMAN' | translate}}
        </ion-select-option>
        <ion-select-option value="en" id="inputSelectedLanguageen">
          {{'GENERAL.ENGLISH' | translate}}
        </ion-select-option>
      </ion-select>
    </div>

    <ion-buttons *ngIf="localStorage.getIsNativeApp()" slot="end">
      <ion-button slot="end" id="showInvitationsButton" (click)="routeToInvitations()">
        <ion-icon name="paper-plane" color="font-header"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-buttons *ngIf="!localStorage.getIsNativeApp()" slot="end">
      <ion-button slot="end" id="logoutButton" (click)="logout()">
        <ion-icon name="power" color="font-header"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div *ngIf="parserService.showAnnouncements && parserService.announcements.length > 0" class="announcement" [ngClass]="parserService.announcements[parserService.selectedAnnouncement].type">
    <div class="right">
      <ion-icon name="close" id="hideAnnouncementsButton" (click)="showHideAnnouncements()"></ion-icon>
    </div>
    <ion-label>
      {{parserService.announcements[parserService.selectedAnnouncement].title}}
    </ion-label>
    <p [class.fontSizeMobile]="isMobile">
    {{parserService.announcements[parserService.selectedAnnouncement].message}}
    </p>
    <div [class.pageButtonsMobile]="isMobile">
      <ion-icon *ngIf="parserService.selectedAnnouncement > 0" name="arrow-back" (click)="previousAnnouncement()"></ion-icon>
      {{parserService.selectedAnnouncement + 1}}
      {{'GENERAL.OF' | translate}}
      {{parserService.announcements.length}}
      <ion-icon *ngIf="parserService.selectedAnnouncement < parserService.announcements.length - 1" name="arrow-forward" (click)="nextAnnouncement()"></ion-icon>
    </div>
  </div>
</ion-header>
